<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import jwt         from '@/http/requests/auth/jwt/index.js'
import AXIOS from './http/axios/index.js'
import i18nData from '../src/i18n/i18nData.js'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  data () {
    return {
      vueAppClasses: []
    }
  },
  watch: {
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
    'isOnline' (val) {
      const api_cache = localStorage.getItem('APIs-cache') ? JSON.parse(localStorage.getItem('APIs-cache')) : []
      if (val && api_cache.length > 0) {
        this.updateAPICache(api_cache)
      }
    }
  },
  methods: {
    updateAPICache (cache) {
      cache.forEach(element => {
        AXIOS({
          method: element.method,
          url: element.url,
          headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`},
          data: element.body
        })
      })
      this.$vs.notify({
        color:'success',
        title: i18nData[this.$i18n.locale]['Success'],
        text: i18nData[this.$i18n.locale]['You are now online. Some local updates were synced successfully!'],
        position: 'top-center'
      })
      localStorage.setItem('APIs-cache', [])
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    }
  },
  mounted () {
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  async created () {
    const TIMESTAMP = Date.now()
    const deleteOldStorge = 'deleteOldStorge'
    if (!JSON.parse(localStorage.getItem(deleteOldStorge))) {
      // when the user access your website for the first time, set the initial and expiresOn values:
      localStorage.setItem(deleteOldStorge, JSON.stringify({
        initial: TIMESTAMP,
        expiresOn: TIMESTAMP + 18000000 // 5hours in ms
      }))
    } else {
    // then, when user access the website again, check the expiresOn, it it's value is bigger than current date
      const EXPIRE_DATE = JSON.parse(localStorage.getItem(deleteOldStorge)).expiresOn
      if (Date.now() > EXPIRE_DATE) {
        localStorage.removeItem('deleteOldStorge')
        localStorage.removeItem('multipleWarehouses')
        localStorage.removeItem('userInfo')
        localStorage.removeItem('idTokenPayload')
        localStorage.removeItem('userMerchant')
        localStorage.removeItem('merchant')
        localStorage.removeItem('notifications')
        localStorage.removeItem('packages')
        localStorage.removeItem('APIs-cache')
        localStorage.removeItem('warehouses')
        localStorage.removeItem('receivedStockRequests')
        localStorage.removeItem('inboundedStockRequest')
        localStorage.removeItem('createdStockRequests')
        localStorage.removeItem('maxItems')
      }
    }
    const userInfo = localStorage.getItem('accessToken') ? VueJwtDecode.decode(localStorage.getItem('accessToken')) : {}
    this.$store.dispatch('updateUserInfo', {
      displayName: userInfo.name,
      email: userInfo.email,
      photoURL: userInfo.picture,
      uid: userInfo.sub 
    })
    this.$acl.change(this.$store.state.AppActiveUser.userRole)
    window.addEventListener('storage', event => {  
      if (event.key === 'accessToken' && !event.newValue) {
        this.$auth.logOut()
      }
    })
    // jwt
    jwt.init()
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)
   
    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)
    
    // Auth0
    // eslint-disable-next-line no-empty
    try       { this.$auth.renewTokens() } catch {}

  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="scss">
label.prompt input {
  display: none;
}
label.prompt span {
  background: #F1F3F6;
  border-radius: 4px;
  border: 0.6px solid #F1F3F6;
}
[dir=ltr] label.prompt span {
  float: left;
}
[dir=rtl] label.prompt span {
  float: right;
}
.checked + span{
  background: #FFFFFF !important;
  border: 0.6px solid #1C5BFE !important;
  color: #1C5BFE !important;
  font-weight: 500 !important;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28) !important;
  border-radius: 4px !important;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
input[type=checkbox] + label.prompt{
  color: #6C84A3;
}
input[type=checkbox]:checked + label{
  background: #FFFFFF;
  border: 0.6px solid #1C5BFE;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
}
.v-select {
  direction: ltr !important;
}
[dir=rtl] .v-select {
  direction: rlt !important;
}
.inactive-link {
  pointer-events: none;
  cursor: pointer;
}
.active-link {
  pointer-events: all;
  cursor: pointer;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.text-darkgray {
  color: #6C84A3;
}
.text-darkblue {
  color: #0A3266;
}
.text-light-red {
  color: #FE8383;
}
.page-title {
  background: rgba(220, 238, 255, 0.4);
  border-radius: 6px;
}
.available-action {
  font-weight: 600;
  background: #1C5BFE;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
}
.unavailable-action {
  font-weight: 600;
  background: #F1F3F6;
  color: #737373;
  border-radius: 4px;
  font-size: 14px;
}
.vs-chip--text {
  text-transform: capitalize !important;
}
[dir=rtl] #order-label-print {
  direction: ltr !important;
}
[dir=rtl] #order-label-print *{
  direction: ltr !important;
}
@media print{
  [dir=rtl] #order-label-print,
  #order-label-print * {
    direction: ltr !important;
  }
}
[dir=ltr] .rotate-label {
  transform: rotate(90deg) !important;
  position: relative !important;
  top: 170px !important;
}
.rotate-label {
  direction: ltr !important;
  transform: rotate(270deg) !important;
}
@media print{
  [dir=rtl] #order-label-print,
  #order-label-print * {
    direction: ltr !important;
  }
  
}
.vs-table--search {
  padding: 0px !important;
  min-width: 100%;
}
.vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
  width: 100% !important;
  border-radius: 6px;
  line-height: 14px;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.28);
}
.fade-tab-enter-active, .fade-tab-enter-to,
.fade-tab-leave-active, .fade-tab-leave-to,
.fade-tab-invert-leave-active, .fade-tab-invert-leave-to,
.fade-tab-invert-enter-active, .fade-tab-invert-enter-to,
.fade-tab-vertical-enter-active, .fade-tab-vertical-enter-to,
.fade-tab-vertical-leave-active, .fade-tab-vertical-leave-to,
.fade-tab-vertical-invert-leave-active, .fade-tab-vertical-invert-leave-to,
.fade-tab-vertical-invert-enter-active, .fade-tab-vertical-invert-enter-to{
  transition: none !important;
  animation: fadeIn 1s m !important;
  -webkit-animation: fadeIn 1s !important;
  -moz-animation: fadeIn 1s !important;
  -o-animation: fadeIn 1s !important;
  -ms-animation: fadeIn 1s !important;
}
.vs-input-number{
  background: none;
}
.vs-input-number-primary button{
  width: 27px;
  height: 27px;
}
.vs-input-number--input{
  font-size: 19px;
}
.vdp-datepicker .vdp-datepicker__calendar {
  padding: 0px 5px 5px;
  background: #f3f5f8;
  border: none;
}
.vdp-datepicker .cell.day {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 4px !important;
  padding: 0 !important;
  margin-bottom: 4px;
}
.vdp-datepicker .cell.day.disabled {
  background: none;
}
.vdp-datepicker__calendar .cell.day-header {
  color: #0A3266;
}
.day__month_btn.up {
  color: #0A3266 !important;
}
.vdp-datepicker__calendar header .prev::after {
  border-right: 10px solid #0A3266;
}
.vdp-datepicker__calendar header .next::after {
  border-left: 10px solid #0A3266;
}
[dir=rtl] .vdp-datepicker__calendar header .prev::after {
  border-left: 10px solid #0A3266 !important;
  border-left: none !important;
}
[dir=rtl] .vdp-datepicker__calendar header .next::after {
  border-right: 10px solid #0A3266 !important;
  border-right: none !important;
}
.pointer-events-none {
  pointer-events: none;
}
.shipments-page {
  min-height: 810px;
}
.add-shipment-dropdown {
  width: 270px;
  ul {
    padding: 4px 12px !important;
    li {
      white-space: nowrap;
      width: 100%;
      background: #F1F3F6;
      border-radius: 4px;
      margin: 8px 0px;
      padding: 4px 0px;
      font-size: 14px;
      line-height: 17px;
      color: #0A3266;
    }
  }
}
textarea.description {
  height: 39px;
  border: none;
  padding: 4px 6px;
  background: rgba(206, 214, 224, 0.24);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #6C84A3;
  &::placeholder {
    color: #9DADC2;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }
}
textarea.notes {
  border: none;
  background: #FFFFFF;
  padding: 12px;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.32);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #6C84A3;
  &::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(157, 173, 194, 0.6);
  }
}
input.input-off {
  border: none;
  padding: 8px;
  background: #F1F3F6;
  border-radius: 4px;
  &::placeholder {
    color: #6C84A3;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
input.input-on {
  border: none !important;
  outline: none !important;
  padding: 8px !important;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(108, 132, 163, 0.28);
  border-radius: 4px;
  color: #1C5BFE;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 18px !important;
  &::placeholder {
    color: #6C84A3;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}
div.input-customer-info .vs-con-input .vs-input--input {
  border: none !important;
  border-bottom: 0.8px solid #9DADC2 !important;
  border-radius: 0px;
  font-size: 14px;
  color: #1C5BFE;
  &:focus {
    box-shadow: none !important;
    border-bottom: 0.8px solid #1C5BFE !important;
  }
}
div.input-customer-info-filled .vs-con-input .vs-input--input {
  border-bottom: 0.8px solid #1C5BFE !important;
}
div.input-customer-info .vs__dropdown-toggle {
  .vs__search {
    color: rgba(0,0,0,.4);
    font-size: .9rem;
  }
  border: none !important;
  border-bottom: 0.8px solid #9DADC2 !important;
  border-radius: 0px;
  font-size: 14px;
}
div.input-customer-info-filled .vs__dropdown-toggle {
  border-bottom: 0.8px solid #1C5BFE !important;
  .vs__selected-options span, .vs__actions button span, .vs__actions span {
    color: #1C5BFE;
  }
}
.input-customer-info.isFocus .vs-input--label,
.input-customer-info .vs-input--label,
.input-customer-info .vs-input--input:focus~.icon-inputx,
.input-customer-info .vs-input--input~.icon-inputx,
.input-customer-info .vs-input--input:focus~.vs-placeholder-label,
.input-customer-info .vs-input--input~.vs-placeholder-label {
  padding-bottom: 0px !important;
  margin-top: 8px;
}
.input-customer-info .vs-input--input~ .vs-input--placeholder,
.input-customer-info .vs-input--input:focus~ .vs-input--placeholder {
  margin-top: 3px;
}
.add-products {
  right: 33px;
  .tooltip {
    box-shadow: 0px 0px 6px rgba(108, 132, 163, 0.6);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent #1C5BFE transparent transparent;
    }
  }
}
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
.remove-icon-position {
  position: absolute;
  top: 5px;
  right: 5px;
}
div.stock-request {
  .vs-input-number {
    * {
      padding: 0px !important;
    }
  }
  .vs-input-number--input {
    font-size: 15px !important;
  }
  .vs-input-number {
    margin: 0px 10px!important;
  }
  .vs-dialog{
    max-width: 800px !important;
  }
}
div.stock-request-input-number {
  button {
    width: 15px !important;
    height: 15px !important;
    
  }
  input.vs-input-number--input {
    font-size: 15px;
  }
}
.remove-search {
  .vs__dropdown-toggle {
    .vs__selected-options {
      input.vs__search {
        display: none !important;
      }
    }
  }
}
.loader::after,
.loader::before {
  content: '';
  margin-bottom: 60px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #1c5bfe;
  position: absolute;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}
@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.status-btn {
  font-weight: 500;
  background: #28C76F;
  border-radius: 6px;
  color: #fff;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
  &:hover {
    background: #fff;
    color: #28C76F;
  }
  transition: background 0.4s ease-in-out, color 0.4s ease-in-out;
}
div.product-dropdown {
  ul.vs__dropdown-menu {
    li.vs__dropdown-option {
      overflow: hidden;
      white-space: normal;
    }
  }
}
.hide-scroll{
  display: none;
}
.actions-dropdown {
  border: none;
  background: none;
  z-index: 999;
  &:focus,
  &:active {
    outline: none;
    .dropdown-list {
      transform: translate(-10px, 0px);
      opacity: 1;
      visibility: visible;
    }
    .actions-btn {
      span:first-child {
        transition: .2s;
        color: lightgray;
      }
    }
  }
  .actions-btn {
    transition: 0.2s ease;
    cursor: pointer;
    background: white;
    border-radius: 25px;
    padding: 5px 15px;
    white-space: nowrap;
    border: 1px solid #d1d1d1;
  }
  .dropdown-list {
    position: absolute;
    background-color: #fff;
    bottom: calc(100% + 4px);
    left: 19px;
    border-radius: 25px;
    box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.32);
    border: 1px solid rgba(0,0,0,.1);
    padding: 2px;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease;
    button {
      border: none;
      background: none;
      cursor: pointer;
      transition: all .2s ease;
      width: 30px;
      height: 30px;
      position: relative;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:hover {
        background: #537ff725;
      }
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
  .dropdown-list-down {
    bottom: unset;
  }
}
.rotate-icon {
  transform: rotate(180deg);
}
.vs-dialog:not(.modal) {
  padding: 6px;
  width: fit-content !important;
  max-width: 700px !important;
  header {
    background: #FFFFFF;
    div h3 {
      padding-bottom: 0px !important;
      font-weight: 600;
      font-size: 18px;
    }
    i {
      position: absolute !important;
      top: calc(50% + 4px);
      right: 3px;
      transform: translate(-50%, -50%) !important;
      padding: 2px;
      font-size: 12px !important;
      border: 1px solid #9DADC2;
      color: #9DADC2;
    }
  }
  .vs-dialog-text {
    font-weight: 500;
  }
  footer {
    button {
      font-weight: 600;
      padding: 8px 16px !important;
    }
  }
}
.scan-input {
  input {
    height: 49px;
  }
}
.con-vs-dialog.new-shipment-modal {
  .vs-dialog {
    max-width: 900px !important;
  }
}
@media (max-width: 576px) {
  .vs-pagination--mb {
    justify-content: center !important;
  }
  .vs-pagination--li{
    min-width: fit-content !important;
    padding: 0px 4px !important;
    width: 23px !important;
    height: 23px !important;
    font-size: 10px !important;
  }
  .vs-pagination--buttons{
    width: 23px !important;
    height: 23px !important;
    font-size: 10px !important;
  }
}
.bg-warning {
  color: '#FF9F43';
}
.text-secondary{
  color: #9A9A9A;
}
.table-responsive-border{
  border-color: #DCDCDC;
}
.leading-0{
  line-height: 0px;
}
.h-btn {
  height: 36px;
}
</style>